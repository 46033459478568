<template>
  <div>
    <div class="container mx-auto">
      <div class="heroBannerWrap">
        <h1 class="heroBannerWrap_text_title displayDesktop">SEASON OF WONDER</h1>
        <p class="heroBannerWrap_text_hastag text-base">Surprise them with shades for every moment.<br>
          Find the perfect gift for everyone on your list with our holiday gift guide.</p>
      </div>
    </div>
    <div v-for="(styleitem, index) in styleitems" :key="index">
      <div class="mx-auto relative">
        <div class="grid md:gap-8 sm:gap-4 md:grid-cols-3 sm:grid-cols-2 product-block-wrapper">
          <div class="product-block" v-for="(productBlock, i) in styleitem.productBlock" :key="i">
            <div v-bind:class="{'productBlock5': i == 1 }">
              <a :href="productBlock.url">
                <img :src="`${productBlock.image}`" :alt="`${productBlock.name}`" class="fes-img1">
              </a>
              <div class="brandPrice_tile">{{ productBlock.name }}</div>
            </div>
          </div>
        </div>
        <div class="product-block-details py-5">
          <h2 class="textThreeItems_title text-left">{{ styleitem.name }}</h2>
          <p class="textThreeItems_subTitle py-5">
            {{styleitem.description }}</p>
          <a class="button bg-black text-white btn px-6 py-3 my-5" :href="styleitem.stylelink">Shop
            {{styleitem.name}}</a>
        </div>
      </div>
      <div class="grid gap-0 md:grid-cols-6 sm:grid-cols-2 my-4">
        <div class="gift-product text-center " v-for="(GiftIdea, i) in styleitem.giftItems" :key="i"
          @mouseover="showByIndex = i" @mouseout="showByIndex = null">
          <a :href="GiftIdea.url" class="card">
            <div>
              <img v-if="showByIndex === i" v-show="showByIndex === i"
                :src="`https://za.sunglasshut.com/shared_assets/product_images/${GiftIdea.image}_030A.jpg`"
                :alt="`${GiftIdea.name}`" />
              <img v-else :src="`https://za.sunglasshut.com/shared_assets/product_images/${GiftIdea.image}_000A.jpg`"
                :alt="`${GiftIdea.name}`" />
            </div>
            <div class="gift-product-name py-2">
              {{ GiftIdea.name }}
            </div>
            <div class="price py-2">
              <span class="price__original">R{{GiftIdea.price}} &nbsp;</span>
            </div>
            <button class="button lg:mt-0 sm:mt-5">Shop Now</button>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "@vue/runtime-core";
import "./gift-guide.scss";
import { useMeta } from "vue-meta/dist/vue-meta.esm-browser";
export default defineComponent({
  name: "giftguide",
  data() {
    return {
      showByIndex: null,
      hover: false,
      active: true,
      timer: null,
      current: 0,
      styleitems: [
        {
          name: 'Iconic styles',
          description: 'For the classic connoisseur. Never out of style, these are a hit on any occasion.',
          stylelink: 'https://za.sunglasshut.com/products/?collections=Iconic%2520Sunglasses',
          productBlock: [
            {
              image: "https://media.sunglasshut.com/2022/10_Holiday/GiftGuide/Personas/Man/01_Iconic.jpg",
              name: "RAY-BAN ",
              url: "https://za.sunglasshut.com/sunglasshut-sa/Ray-Ban/RB3636-NEW-CARAVAN-8056597641784",
            },
            {
              image: "https://cdn.zando.co.za/cms/sunglasshut/gift-guide/220725_SGH149512.jpg",
              name: "RAY-BAN ",
              url: "https://za.sunglasshut.com/sunglasshut-sa/Ray-Ban/RB4416-New-Clubmaster-8056597801706",
            },
            {
              image: "https://cdn.zando.co.za/cms/sunglasshut/gift-guide/220725_SGH149864.jpg",
              name: "TOM FORD",
              url: "https://za.sunglasshut.com/sunglasshut-sa/Tom%20Ford/TR001363-FT0907-889214292858",
            },
          ],
          giftItems: [
            {
              image: "8056597328111",
              name: "Ray-Ban",
              url: "https://za.sunglasshut.com/sunglasshut-sa/Ray-Ban/RB3025-AVIATOR-LARGE-METAL-8056597328111",
              price: "3100",
            },
            {
              image: "889214122414",
              name: "Tom Ford",
              url: "https://za.sunglasshut.com/sunglasshut-sa/Tom%20Ford/FT0777-N-889214122414",
              price: "6590",
            },
            {
              image: "725125941938",
              name: "Micheal Kors",
              url: "https://za.sunglasshut.com/sunglasshut-sa/Oliver%20Peoples/OV5454SU-DESMON-SUN-725125941938",
              price: "2000",
            },
            {
              image: "827934470460",
              name: "Oliver Peoples",
              url: "https://za.sunglasshut.com/sunglasshut-sa/Oliver%20Peoples/OV5454SU-DESMON-SUN-827934470460",
              price: "6370",

            },
            {
              image: "805289304449",
              name: "Ray-Ban",
              url: "https://za.sunglasshut.com/sunglasshut-sa/Ray-Ban/RB3016-CLUBMASTER-805289304449",
              price: "2300",
            },
            {
              image: "8056597626682",
              name: "Ray-Ban",
              url: "https://za.sunglasshut.com/sunglasshut-sa/Ray-Ban/RB2298-HAWKEYE-8056597626682",
              price: "3460",
            },
          ]
        },
        {
          name: 'LUXURY STYLES ',
          description: 'For the classic connoisseur. Never out of style, these are a hit on any occasion.',
          stylelink: 'https://za.sunglasshut.com/products/?collections=Luxury%2520Sunglasses',
          productBlock: [
            {
              image: "https://cdn.zando.co.za/cms/sunglasshut/gift-guide/ELLA_LOOK1_RESHOOT_048_B.jpg",
              name: "PRADA",
              url: "https://za.sunglasshut.com/sunglasshut-sa/Prada/PR-19ZS-8056597744218",
            },
            {
              image: "https://cdn.zando.co.za/cms/sunglasshut/gift-guide/OMAR_LOOK2_057_B_(1).jpg",
              name: "VERSACE",
              url: "https://za.sunglasshut.com/sunglasshut-sa/Burberry/BE4370U-MARGOT-8056597731560",
            },{
              image: "https://cdn.zando.co.za/cms/sunglasshut/gift-guide/220725_SGH149813.jpg",
              name: "BURBERRY",
              url: "https://za.sunglasshut.com/sunglasshut-sa/Versace/VE4436U-8056597792387",
            },
          ],
          giftItems: [
            {
              image: "8056597800006",
              name: "Dolce & Gabbana",
              url: "https://za.sunglasshut.com/sunglasshut-sa/Dolce%20&%20Gabbana/DG4384-8056597800006",
              price: "3260",
            },
            {
              image: "8056597657983",
              name: "Dolce&Gabbana",
              url: "https://za.sunglasshut.com/sunglasshut-sa/Dolce&Gabbana/DG4403--8056597657983",
              price: "4170",

            },
            {
              image: "8056597792394",
              name: "Versace",
              url: "https://za.sunglasshut.com/sunglasshut-sa/Versace/VE4437U-8056597792394",
              price: "3860",
            },
            {
              image: "8056597627498",
              name: "Prada",
              url: "https://za.sunglasshut.com/sunglasshut-sa/Prada/PR-23YS--8056597627498",
              price: "5510",
            },
            {
              image: "889652048857",
              name: "Gucci",
              url: "https://za.sunglasshut.com/sunglasshut-sa/Gucci/GG0035S-889652048857",
              price: "6290",
            },
            {
              image: "8056597435635",
              name: "Prada",
              url: "https://za.sunglasshut.com/sunglasshut-sa/Prada/PR-16WS--8056597435635",
              price: "5020",
            },
          ]
        },
        {
          name: 'SPORT STYLES ',
          description: 'For the classic connoisseur. Never out of style, these are a hit on any occasion.',
          stylelink: '/products/?collections=Active%2520Sunglasses',
          productBlock: [
            {
              image: "https://cdn.zando.co.za/cms/sunglasshut/gift-guide/MATTIA_LOOK1B_019_B.jpg",
              name: "OAKLEY",
              url: "https://za.sunglasshut.com/sunglasshut-sa/Oakley/OO9100-Leffingwell-888392600769",
            },
            {
              image: "https://cdn.zando.co.za/cms/sunglasshut/gift-guide/220725_SGH149574.jpg",
              name: "COSTA",
              url: "https://za.sunglasshut.com/sunglasshut-sa/Costa%20Del%20Mar/6S9085-Santiago-97963939591",
            },
            {
              image: "https://cdn.zando.co.za/cms/sunglasshut/gift-guide/220725_SGH150080.jpg",
              name: "OAKLEY",
              url: "https://za.sunglasshut.com/sunglasshut-sa/Oakley/OO9126-REEDMACE-888392581266",
            },
          ],
          giftItems: [
            {
              image: "8056597766333",
              name: "Prada Linea Rossa",
              url: "https://za.sunglasshut.com/sunglasshut-sa/Prada%20Linea%20Rossa/PS-02YS-8056597766333",
              price: "4490",

            },
            {
              image: "888392583109",
              name: "Oakley",
              url: "https://za.sunglasshut.com/sunglasshut-sa/Oakley/OO9098-Re:SubZero-888392583109",
              price: "3340",
            },
            {
              image: "8056597090209",
              name: "Prada Linea Rossa ",
              url: "https://za.sunglasshut.com/sunglasshut-sa/Oakley/OO9126-REEDMACE-8056597090209",
              price: "4020",
            },
            {
              image: "8056597374309",
              name: "Prada Linea Rossa",
              url: "https://za.sunglasshut.com/sunglasshut-sa/Prada%20Linea%20Rossa/PS-01XS--8056597374309",
              price: "3550",

            },
            {
              image: "888392557629",
              name: "Oakley",
              url: "https://za.sunglasshut.com/sunglasshut-sa/Oakley/OO9471-Encoder-888392557629",
              price: "3490",

            },
            {
              image: "888392260109",
              name: "Oakley ",
              url: "https://za.sunglasshut.com/sunglasshut-sa/Oakley/OO9102-HOLBROOK-888392260109",
              price: "2930",
            },
          ]
        },
        {
          name: 'ON-TREND STYLES ',
          description: 'For the classic connoisseur. Never out of style, these are a hit on any occasion.',
          stylelink: '/products/?collections=On-trend%2520Sunglasses',
          productBlock: [
            {
              image: "https://cdn.zando.co.za/cms/sunglasshut/gift-guide/PABLO_LOOK1_041_B.jpg",
              name: "BURBERRY",
              url: "https://za.sunglasshut.com/sunglasshut-sa/Burberry/BE4291-8056597787246",
            },
            {
              image: "https://cdn.zando.co.za/cms/sunglasshut/gift-guide/220725_SGH149848.jpg",
              name: "Ray-Ban",
              url: "https://za.sunglasshut.com/sunglasshut-sa/Ray-Ban/RB4389-8056597718363",
            },
            {
              image: "https://cdn.zando.co.za/cms/sunglasshut/gift-guide/220725_SGH149647.jpg",
              name: "OAKLEY",
              url: "https://za.sunglasshut.com/sunglasshut-sa/Oakley/OO9465-Sutro-Lite-Sweep-888392600776",
            },
          ],
          giftItems: [
            {
              image: "8056597594608",
              name: "Persol",
              url: "https://za.sunglasshut.com/sunglasshut-sa/Persol/PO3286S-8056597594608",
              price: "3910",
            },
            {
              image: "8053672947397",
              name: "Versace ",
              url: "https://za.sunglasshut.com/sunglasshut-sa/Versace/VE4361--8053672947397",
              price: "3880",
            },
            {
              image: "8056597683784",
              name: "Ray-Ban",
              url: "https://za.sunglasshut.com/sunglasshut-sa/Ray-Ban/RB2140-WAYFARER-8056597683784",
              price: "2500",
            },
            {
              image: "8056597599719",
              name: "Burberry ",
              url: "https://za.sunglasshut.com/sunglasshut-sa/Burberry/BE4358-KNIGHT-8056597599719",
              price: "2950",
            },
            {
              image: "725125383448",
              name: "Michael Kors",
              url: "https://za.sunglasshut.com/sunglasshut-sa/Michael%20Kors/MK2170U-KARLIE-725125383448",
              price: "2330",
            },
            {
              image: "8056597540735",
              name: "Versace",
              url: "https://za.sunglasshut.com/sunglasshut-sa/Versace/VE2225--8056597540735",
              price: "3880",

            },
          ]
        },
      ],
      methods: {
        imageChange() {
          this.hover = true;
          this.active = false;
        },
      }
    }
  },
  setup() {
    useMeta({
      title:
        "Sunglass Hut® South Africa Online Store | Gift Guide",
      description:
        "Surprise them with shades for every moment. Find the perfect gift for everyone on your list with our holiday gift guide.",
    });
  },
})
</script>
